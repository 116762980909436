import React, { useEffect, useState } from 'react';

const Age: React.FC = () => {
    const [age, setAge] = useState<number>(0);

    useEffect(() => {
        const calculateAge = () => {
            const birthDate = new Date('2006-08-01T00:00:00Z');
            const now = new Date();
            const ageInMilliseconds = now.getTime() - birthDate.getTime();
            const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
            setAge(parseFloat(ageInYears.toFixed(10)));
        };

        calculateAge();
        const interval = setInterval(calculateAge, 1000);

        return () => clearInterval(interval);
    }, []);

    return <span>{age}</span>;
};

export default Age;
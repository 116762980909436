import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Navody.module.css';

interface Post {
    image: string;
    title: string;
    slug: string;
}

const Navody: React.FC = () => {
    const [posts, setPosts] = useState<Post[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetch('/navody.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error with loading data.');
                }
                return response.json();
            })
            .then(data => {
                setPosts(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <div>Načítání...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className={styles['container']}>
            <h1 className={styles['index-headings']}>NÁVODY</h1>
            <ul className={styles['list']}>
                {posts.map((post, index) => (
                    <li key={index} className={styles['listItem']}>
                        <Link to={`/navody/${post.slug}`} className={styles['post-link']}>
                            <div className={styles['post-image']}>
                                <img src={post.image} alt={post.title} />
                            </div>
                            <div className={styles['post-title']}>{post.title}</div>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Navody;

import React from 'react';
import './NotFound.css'; // Importujte soubor CSS pro styly

const NotFound: React.FC = () => {
    return (
        <div className="notfound-container">
            {/* <img className="error-cat" src="https://http.cat/404"></img> */}
            <h1>404</h1>
            <p>Stránka kterou hledáte nebyla nalezena.</p>
            <a href="/" className="back-home-link">Vrátit se na domovskou stránku</a>
        </div>
    );
};

export default NotFound;

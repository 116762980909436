import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface BlogPostContent {
    title: string;
    date: string;
    content: string;
}
const convertMarkdownToHTML = (markdown: string): string => {
    // inf
    let html = markdown.replace(/\[inf\](.*?)\[\/inf\]/g, '<div class="info-block">$1</div>');

    // id
    html = html.replace(/id\[(.*?)\]\(#(.*?)\)/g, '<span id="$2">$1</span>');

    // img
    html = html.replace(/!\[([^\]]*)\]\(([^)]+)\)/g, '<img src="$2" alt="$1" />');

    // loc url
    html = html.replace(/\?\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>');
    
    // url
    html = html.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>');

    // bold
    html = html.replace(/\*\*([^*]+)\*\*/g, '<strong>$1</strong>');

    // em
    html = html.replace(/\*([^*]+)\*/g, '<em>$1</em>');

    // strike
    html = html.replace(/~~([^~]+)~~/g, '<del>$1</del>');

    // underline
    html = html.replace(/__([^_]+)__/g, '<u>$1</u>');

    // list
    html = html.replace(/^- (.*)$/gm, '<li>$1</li>');
    html = html.replace(/(<li>.*<\/li>\s*)+/g, '<ul>$&</ul>');

    // headings
    html = html.replace(/### (.*)/g, '<h3>$1</h3>');
    html = html.replace(/## (.*)/g, '<h2>$1</h2>');

    // pre
    html = html.replace(/```([\s\S]*?)```/g, '<pre><code>$1</code></pre>');

    // kap
    // html = html.replace(/-- (.*)/g, '<div>$1</div>');

    // remove blank p
    html = html.replace(/<p><\/p>/g, '');

    // new lines
    html = html.replace(/([^\n])\n(?!<li>|<pre>|<ul>|<h[2-3]>|<\/?p>)/g, '$1<br />');

    // p processing
    html = html.replace(/(?<!<pre>|<h[2-3]>|<\/?p>|<ul>|<li>)([\s\S]+?)(?=\n{2,}|$)/g, '<p>$1</p>');

    // remove double p
    html = html.replace(/<\/p>\s*<p>/g, '</p><p>');

    return html;
};

const BlogPost: React.FC = () => {
    const { slug } = useParams<{ slug: string }>();
    const [post, setPost] = useState<BlogPostContent | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (slug) {
            fetch(`/p/${slug}.md`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Příspěvek nebyl nalezen.');
                    }
                    return response.text();
                })
                .then(text => {
                    const [titleLine, dateLine, ...contentLines] = text.split('\n');
                    const content = convertMarkdownToHTML(contentLines.join('\n'));

                    setPost({
                        title: titleLine.replace('# ', ''),
                        date: dateLine.replace('**', '').replace('**', ''),
                        content: content,
                    });
                })
                .catch(error => {
                    setError(error.message);
                });
        }
    }, [slug]);

    if (error) {
        return <div>{error}</div>;
    }

    if (!post) {
        return <div>Načítání...</div>;
    }

    return (
        <div className="blog-container">
            <h1>BLOG</h1>
            <hr />
            <h2 className="PostTitle">{post.title}</h2>
            <p>{post.date}</p>
            <hr /><br />
            <div dangerouslySetInnerHTML={{ __html: post.content }} />
        </div>
    );
};

export default BlogPost;

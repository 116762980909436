import React, { useState, useEffect } from 'react';

interface GameData {
  platform: string;
  rom_path: string;
  status: string;
}

const CurrentGame: React.FC = () => {
  const [gameData, setGameData] = useState<GameData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://75.119.136.218:3636/current');
        const data: GameData = await response.json();
        setGameData(data);
      } catch (error) {
        console.error('Error fetching game data:', error);
      }
    };

    fetchData();
  }, []);

  if (!gameData) {
    return <div>Loading...</div>;
  }

  const { platform, rom_path, status } = gameData;

  // Extrakce názvu hry z cesty k souboru
  const gameName = rom_path?.split('/').pop()?.replace('.iso', '') || '';

  return (
    <div>
      {status === "playing" ? (
        <p>Aktuálně hraješ: {gameName} na {platform?.toUpperCase()}</p>
      ) : (
        <p>Aktuálně nic nehraješ.</p>
      )}
    </div>
  );
};

export default CurrentGame;

import React from 'react';
import './ratings.css';
import { FaPlus, FaMinus } from 'react-icons/fa';

interface LinuxData {
    name: string;
    image: string;
    rating: number;
    ratingText: string;
    description: string;
    pros: string[];
    cons: string[];
    link: string;
}

const linuxData: LinuxData[] = [
    {
        name: 'Mint',
        image: '../assets/linux/mint.webp',
        rating: 10,
        ratingText: 'Prostě super 👍',
        description: 'Za mě skvělé distro pro první přechod z winu nebo macu. Používal jsem Cinnamon verzi asi po dobu 2 měsíců a je opravdu skvělá, více user-friendly linux jsem dlouho neviděl. Zatím nemám co vytknout.',
        pros: ['Jednoduché UI a instalace', 'Ideální pro začátečníky'],
        cons: ['Asi nic'],
        link: 'https://linuxmint.com'
    },
    {
        name: 'ZorinOS',
        image: '../assets/linux/zorin.png',
        rating: 9,
        ratingText: 'Skvělý 👌',
        description: 'Další jednoduchý linux, který bych doporučil lidem co přechází z winu nebo macu. Za mě pěkné UI, jednoduchý přístup k nastavení a instalaci drivů. Výhoda je i vlastní App Store, který zas tolik distribucí nemá.', 
        pros: ['Jednoduché UI a instalace', 'Vlastní App Store', 'Pravidelné fixy'],
        cons: ['Zabugovaný dark mode', 'Není pro starší HW (ani Lite)'],
        link: 'https://zorin.com/os/'
    },
    {
        name: 'Arch',
        image: '../assets/linux/arch.webp',
        rating: 8,
        ratingText: 'I use arch btw 😎',
        description: 'Pěkné a čisté distro, které už je spíše pro zkušenější linuxáře, mezi které zas tolik nepatřím. Instalace určtitě není jednoduchá, ale systém jako takový už je hodně fajn. Rozjede se i na bramboře a nemá téměř nic předinstalovaného, za to velkej palec nahoru. Pokud člověku nevadí bolest při instalaci tak určitě doporučuji.', 
        pros: ['Čistý & nenáročný systém', 'Příjemný design'],
        cons: ['Příšerná dokumentace', 'Bolest s instalací'],
        link: 'https://archlinux.org'
    },
    {
        name: 'Ubuntu',
        image: '../assets/linux/ubuntu.png',
        rating: 6,
        ratingText: 'Overrated 🙂',
        description: 'Ubuntu využívám neustále, ale v serverové verzi, která je super. Co se týče této desktopové jsem zas takové očekávání neměl. Je to jen debian který má doinstalované některé package a modernější UI, to je vše. Lákavý je, použitelný taky ale v praxi bych ho na počítač asi nedal.', 
        pros: ['Pěkné UI/UX', 'Jednoduchý na používání'],
        cons: ['Náročnější debian', 'Zbytečné aplikace'],
        link: 'https://ubuntu.com'
    }
];

const getRatingColor = (rating: number) => {
    if (rating <= 3) return 'red';
    if (rating <= 7) return 'orange';
    return 'green';
};

const Linux: React.FC = () => {
    return (
        <div className="linux-container">
            <h1>LINUX DISTRIBUCE</h1>
            <p className="linux-intro">Mé objektivní hodnocení linux distribucí</p>
            {linuxData.map((linux, index) => (
                <div key={index} className="linux-box">
                    <div className="linux-header">
                        <img src={linux.image} alt={linux.name} className="linux-image" />
                        <h3>{linux.name}</h3>
                        <div className="rating-box">
                            <span className="rating-text">{linux.ratingText}</span>
                            <div className="linux-rating" style={{ color: getRatingColor(linux.rating) }}>
                                {linux.rating}/10
                            </div>
                        </div>
                    </div>
                    <p className="linux-description">{linux.description}</p> {}
                    <div className="linux-details">
                        <div className="pros-cons">
                            <div className="pros">
                                <ul>
                                    {linux.pros.map((pro, i) => (
                                        <li key={i}><FaPlus /> {pro}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="cons">
                                <ul>
                                    {linux.cons.map((con, i) => (
                                        <li key={i}><FaMinus /> {con}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <p><strong>Ještě neohodnoceno: </strong>Debian, Pop!_OS, Fedora, Garuda, Gentoo</p>
        </div>
    );
};

export default Linux;

import React from 'react';
import './Knowledge.css';

const Knowledge: React.FC = () => {
    const skills = [
        { name: 'HTML', value: 100 },
        { name: 'CSS', value: 100 },
        { name: 'M. Office', value: 100 },
        { name: 'Responzivita', value: 66 },
        { name: 'C#', value: 66 },
        { name: 'C++ (Micro)', value: 66 },
        { name: 'Python', value: 66 },
        { name: 'Git', value: 66 },
        { name: 'SQLite', value: 66 },
        { name: 'Javascript', value: 66 },
        { name: 'React TS', value: 33 },
        { name: 'Node.js', value: 33 },
        { name: 'Flutter', value: 33 },
        { name: 'Photoshop', value: 33 },
    ];

    const certifications = [
        { name: 'CCNAv7: Introduction to Networks', issuer: 'Cisco Networking Academy' },
        { name: 'Introduction to Cybersecurity', issuer: 'Cisco Networking Academy' },
        { name: 'The Complete 2023 Web Development Bootcamp', issuer: 'Udemy' },
        { name: 'Introduction to HTML', issuer: 'Sololearn' },
        { name: 'Introduction to CSS', issuer: 'Sololearn' },
        { name: 'Advanced CSS Media Queries', issuer: 'LinkedIn' },
        { name: 'ECDL M2, M3, M4, M6, M7, M9, M10', issuer: 'ECDL' },
        { name: 'Generative AI Fundamentals', issuer: 'Google Cloud Skills Boost' },
    ];

    return (
        <div className="knowledge-container">
            <h2>ZNALOSTI</h2>
            <div className="level-indicators">
                <span id="beginner">Základní</span>
                <span id="intermediate">Střední</span>
                <span id="master">Výborné</span>
            </div>
            <div className="knowledge-bars">
                {skills.map((skill, index) => (
                    <div key={index} className="knowledge-item">
                        <span className="knowledge-skill">{skill.name}</span>
                        <div className="knowledge-bar-container">
                            <progress
                                className="knowledge-bar"
                                value={skill.value}
                                max="100"
                            >
                                {skill.value}%
                            </progress>
                        </div>
                    </div>
                ))}
            </div>
            <h2>CERTIFIKACE</h2>
            <div className="certifications">
                {certifications.map((cert, index) => (
                    <div key={index} className="certification-item">
                        <span className="certification-name">{cert.name}</span>
                        <span className="certification-issuer"> - {cert.issuer}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Knowledge;

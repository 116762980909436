import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Blog.css';

interface BlogPost {
    date: string;
    title: string;
    slug: string;
}

const Blog: React.FC = () => {
    const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);

    useEffect(() => {
        fetch('/blogPosts.json')
            .then(response => response.json())
            .then(data => setBlogPosts(data))
            .catch(error => console.error('Error loading blog posts:', error));
    }, []);

    return (
        <div className="blog-container">
            <h1>BLOG</h1>
            <ul className="post-list">
                {blogPosts.map((post, index) => (
                    <li key={index} className="post-item">
                        <span className="post-date">{post.date}</span>
                        <span className="post-title">{post.title}</span> 
                        <Link to={`/blog/${post.slug}`} className="post-link">➤</Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Blog;

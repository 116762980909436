import React from 'react';
import './ratings.css';
import { FaPlus, FaMinus } from 'react-icons/fa';

interface Data {
    name: string;
    image: string;
    rating: number;
    ratingText: string;
    description: string;
    pros: string[];
    cons: string[];
    link: string;
}

const Data: Data[] = [
    {
        name: 'Macro klávesnice',
        image: '/assets/ratings/aliexpress/tech/macro-klavesnice.png',
        rating: 9,
        ratingText: 'Výborná 👍',
        description: 'Super macro klávesnice za pár korun. Dost mě překvapil materiál a jak skvěle funguje. Až na čínský SW nemám asi co vytknout.',
        pros: ['Plug & Play', 'Pěkný design', 'Pevný materiál', 'Céčko 🤯'],
        cons: ['Čínskej SW (stačí jednou)', 'Nešlo nastavit RGB'],
        link: 'https://linuxmint.com'
    }
];

const getRatingColor = (rating: number) => {
    if (rating <= 3) return 'red';
    if (rating <= 7) return 'orange';
    return 'green';
};

const Linux: React.FC = () => {
    return (
        <div className="linux-container">
            <h1>ALIEXPRESS</h1>
            <p className="linux-intro">Mé objektivní hodnocení aliexpress tech produktů</p>
            {Data.map((linux, index) => (
                <div key={index} className="linux-box">
                    <div className="linux-header">
                        <img src={linux.image} alt={linux.name} className="linux-image" />
                        <h3>{linux.name}</h3>
                        <div className="rating-box">
                            <span className="rating-text">{linux.ratingText}</span>
                            <div className="linux-rating" style={{ color: getRatingColor(linux.rating) }}>
                                {linux.rating}/10
                            </div>
                        </div>
                    </div>
                    <p className="linux-description">{linux.description}</p> {}
                    <div className="linux-details">
                        <div className="pros-cons">
                            <div className="pros">
                                <ul>
                                    {linux.pros.map((pro, i) => (
                                        <li key={i}><FaPlus /> {pro}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="cons">
                                <ul>
                                    {linux.cons.map((con, i) => (
                                        <li key={i}><FaMinus /> {con}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Linux;

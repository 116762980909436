import React from 'react';
import { FaCopy, FaExternalLinkAlt } from 'react-icons/fa';
import './Donate.css';

const SupportSection: React.FC = () => {
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    alert('Zkopírováno.');
  };

  return (
    <div>
        <div className="support-section">
      <div className="support-column">
        <h2>Donate</h2>
        {[
          { header: 'Bankovní účet', value: '670100-2215776729/6210', id: '' },
          { header: 'IBAN', value: 'CZ2862106701002215776729', id: '' },
          { header: 'PayPal', value: 'paypal.com/paypalme/ondra907', id: '' },
          { header: 'BTC', value: 'bc1qpyenph6jxlldg9uhmxv4e50tdgexalle4smvxn', id: 'btc' },
          { header: 'ETH', value: '0x26Eb180BfCEB6404eB25fBc2a0919f5b6B6e25c9', id: 'eth' },
        ].map((item) => (
          <div key={item.header} className="support-item">
            <div>
              <div className="support-item-header">{item.header}</div>
              <div id={item.id} className="support-item-value">{item.value}</div>
            </div>
            <FaCopy
              className="copy-icon"
              onClick={() => handleCopy(item.value)}
              title="Copy"
            />
          </div>
        ))}
      </div>
      <div className="support-column">
        <h2>Refferal</h2>
        {[
          { header: 'Česká spořitelna', link: 'https://csas.cz/mgm?orig=AF77EFCDDCBD41FD6349', text: 'Zdarma 400kč po dobu 3 měsíců, potřebné vždy 4 platby.' },
          { header: 'Revolut', link: 'https://revolut.com/referral/?referral-code=ondejr5t0!NOV1-24-AR-L1', text: '' },
          { header: 'Binance', link: 'https://www.binance.com/activity/referral-entry/CPA/together-v4?hl=cs&ref=CPA_008E7A3NPC', text: '' },
        ].map((item) => (
          <div key={item.header} className="support-item">
            <div>
            <div className="support-item-header">{item.header}</div>
            <div className="support-item-value" id="ref-item-value">{item.text}</div>
            </div>
            <FaExternalLinkAlt
              className="copy-icon"
              onClick={() => window.open(item.link, '_blank')}
              title="Zkopírovat"
            />
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default SupportSection;

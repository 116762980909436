import React from 'react';
import './LinkTree.css';

interface LinkTreeItem {
    title: string;
    url: string;
    icon: string;
}

interface LinkTreeProps {
    links: LinkTreeItem[];
}

const LinkTree: React.FC<LinkTreeProps> = ({ links }) => {
    return (
        <div className="linktree-container">
            <h1 className="linktree-title">MOJE ODKAZY</h1>
            <ul className="linktree-list">
                {links.map((link, index) => (
                    <li key={index} className="linktree-item">
                        <a href={link.url} target="_blank" rel="noopener noreferrer" className="linktree-link">
                            <img src={link.icon} alt={link.title} className="linktree-icon" />
                            <span className="linktree-text">{link.title}</span>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LinkTree;

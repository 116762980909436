import React, { useEffect, useState } from 'react';
import './Tech.css';

interface TechItem {
    name: string;
    link: string;
    icon: string;
    description: string;
}

interface TechCategory {
    category: string;
    items: TechItem[];
}

const Tech: React.FC = () => {
    const [techCategories, setTechCategories] = useState<TechCategory[]>([]);

    useEffect(() => {
        fetch('/tech.json')
            .then((response) => response.json())
            .then((data) => setTechCategories(data))
            .catch((error) => console.error('Error loading tech data:', error));
    }, []);

    return (
        <div className="tech-container">
            <h1 className="tech-title">TECHNIKA</h1>
            {techCategories.map((category, index) => (
                <div key={index} className="tech-category">
                    <div className="category-header">
                        <hr className="line" />
                        <h2>{category.category}</h2>
                        <hr className="line" />
                    </div>
                    <div className="tech-grid">
                        {category.items.map((item, itemIndex) => (
                            <div key={itemIndex} className="tech-box">
                                <img src={item.icon} alt={`${item.name} icon`} className="tech-icon" />
                                <div className="tech-content">
                                    <h3>{item.name}</h3>
                                    <p>{item.description}</p>
                                </div>
                                <a href={item.link} className="tech-link" target="_blank" rel="noopener noreferrer">
                                    <span className="link-text">Odkaz</span>
                                    <i className="link-icon">🔗</i>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Tech;

import React from 'react';
import './AliexMenu.css';
import { FaPlus, FaMinus, FaMobileAlt, FaLaptop, FaTools } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Aliexpress: React.FC = () => {
    return (
        <div className="aliex-ratings-container">
            <h1>ALIEXPRESS</h1>
            <p className="aliex-ratings-intro">Mé objektivní hodnocení aliexpress produktů</p>

            <Link to='/ratings/aliexpress/tech'>
                <div className="aliex-ratings-box">
                    <div className="aliex-ratings-header">
                        <FaTools className="aliex-ratings-icon" />
                        <h3>Technika</h3>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default Aliexpress;
